import React from 'react';

import { Box, Container } from '@mui/material';
import Typography from '@mui/material/Typography';

import { StarBackground } from 'components/atoms/StarBackground';
import Layout from 'components/templates/Layout';

export const NotFound = () => (
  <Layout>
    <Box
      sx={{ position: 'relative', height: '100vh', filter: 'grayscale(75%)' }}
    >
      <StarBackground />

      <Container
        maxWidth="lg"
        sx={{
          height: '100%',
        }}
      >
        <Box
          sx={{
            height: '100%',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            textAlign: 'center',
            mx: 3,
          }}
        >
          <img
            className="select-none pointer-events-none"
            src="https://storage.googleapis.com/wm-cdn/Logo/2x/WM_logo_coloured%402x.png"
            alt="logo"
          />
          <Typography
            variant="h2"
            className="text-white font-semibold text-5xl  md:whitespace-nowrap text-center"
          >
            Ops! seems like you&apos;ve got lost in space!
          </Typography>
        </Box>
      </Container>
    </Box>
  </Layout>
);

export default NotFound;
